export default{
    common:{
        userName:'chaungrui',
        ok: 'OK',
        cancel: 'Cancel',
        yes: 'Yes',
        no: 'No',
        export: 'Export',
        checkDetail: 'Check Detail',
        emailCustomerID: 'Email/Customer ID',
        pwd: 'Pwd',
        login: 'Login',
        fogetPwd: 'Foget Pwd',
        emailCollectPwd: 'Email to collect your Pwd',
        email : 'Email ',
        emailNotCorrect: 'Email format is not correct',
        OTP: 'OTP',
        plsEnterOTP: 'Pls enter your OTP',
        plsEnterEmail: 'Pls enter your Email',
        plsEnterEmailOTP: 'Pls enter your Email OTP',
        retrieveOTP: 'Retrieve your OTP',
        newPwd: 'New Pwd',
        plsEnterNewPwd: 'Pls enter new Pwd',
        pwdFormatNotCorrect: ' Pwd format is not correct',
        findPwd: 'Find Pwd',
        returnLogin: 'Return to Login',
        readAndAgree: 'I have read and agree',
        setting: 'Setting',
        accountDetails: 'Account details',
        certifiedDetails: 'Certified Details',
        companyName: 'Company Name',
        certifiedEntity: 'Certified Entity',
        accountDetails: 'Account Details',
        userAccount: 'User Account',
        emailTmp: 'E-mail',
        topUpCenter: 'Top Up Center',
        bill: 'Bill',
        invoiceDetail: 'Invoice detail',
        billingTimeZone: 'Billing Time Zone',
        billingCycle: 'Billing Cycle',
        settlementCurrency: 'Settlement Currency',
        balance: 'Balance',
        balanceAlert: 'Balance alert!',
        creditLimits: 'Credit Limits',
        creditAlert: 'Credit Alert!',
        noticeEmail: 'Notice Email',
        Save: 'Save',
        privacyAgreement: 'Privacy Agreement',
        logout: 'Logout',
        internationalSMS: 'International SMS',
        preview: 'Preview',
        sentStatistics: 'Sent statistics',
        volSubmitted: 'Vol Submitted',
        volSucceed: 'Vol Succeed',
        volFailed: 'Vol Failed',
        unknown: 'Unknown',
        volError: 'Vol of Error',
        otherStatistics: 'Other Statistics',
        expenseStatistics: 'Expense statistics',
        checkDetails: 'Check Details',
        application: 'Application',
        countryRegion: 'Country/Region',
        countryRegionTmp: 'Country Region',
        plsSelectCountryRegion: 'Pls select country/Region',
        plsSelectOperator: 'Pls select Operator',
        qty: 'Qty',
        used: 'Used',
        totalUsed: 'Total Used',
        topExpenseStatistics: 'Top 1-10 Expense statistics',
        submittedStatistics: 'Submitted statistics',
        totalSubmitted: 'Total Submitted',
        appliedAccount: 'Applied Account',
        plsEnterApplicationID: 'Pls enter Application ID',
        plsEnterApplicationName: 'Pls enter application Name',
        plsEnterApplicationType: 'Pls enter application type',
        plsSelectApplication: 'Pls select application',
        applicationID: 'Application ID',
        applicationName: 'Application Name',
        applicationType: 'Application Type',
        otp: 'OTP',
        mkt: 'MKT',
        account: 'Account',
        pwd: 'Pwd',
        status: 'Status',
        off: 'Off',
        on: 'On',
        timeCreated: 'Time created',
        onlineDelivering: 'Online Delivering',
        plsSelectApplicationAccount: 'Pls select application account',
        content: 'Content',
        phoneNumber: 'Phone Number',
        addManually: 'Add manually',
        importBatch: 'Import by Batch',
        uploadFile: 'Upload File',
        downloadTemplate: 'Download Template',
        supportFileFormat: 'Support xls,xlsx file format',
        fileMaximum: 'File format Maximum 500M',
        allNumbers: 'All Numbers',
        clickEmpty: 'Click to empty',
        walidNumber: 'Valid Number',
        invalidNumber: 'Invalid Number',
        duplicatedNumber: 'Duplicated Number',
        check: 'Check',
        country: 'Country',
        submitSchedule: 'Submit by schedule',
        plsSelect: 'Pls select',
        plsSelectSubmitTime: 'Pls select submit time',
        telephoneNumber: 'Telephone Number',
        plsEnterPhoneNumber: 'Pls enter phone number',
        countryCode: 'Country code',
        sendSubmission: 'Send by submission',
        sending: 'Sending...',
        batchRecord: 'Batch Record',
        plsEnterSender: 'Pls enter Sender',
        batchNo: 'Batch No.',
        application: 'Application',
        SMSContent: 'SMS content',
        plsEnterSMSContent: 'Pls enter SMS content',
        submitSMS: 'Submit SMS',
        noCounted: 'No. Counted',
        SMSOriginated: 'SMS Originated',
        submitTime: 'Submit Time',
        action: 'Action',
        singleRecord: 'Single Record',
        operator: 'Operator',
        submitTime: 'Submit Time',
        deliverTime: 'Deliver Time',
        notification: 'Notification',
        setting: 'Setting',
        exit: 'Exit',
        sendingRecords: 'Sending Records',
        startDate: "Start Date",
        endDate: "End Date",
        dataOverview: 'Data Overview',
        inquiry: 'Inquiry',
        countriesTrafficStatistics: 'TOP1-10 countries traffic statistics',
        submissionStatistics: 'Submission statistics',
        userProtocol: 'User Protocol',
        alreadyReadPrivacyProtocol: 'You have already read the privacy protocol.',
        loadingFailed: 'Loading failed',
        systemAbnormal: 'System abnormal',
        applicationAccount: 'Application Account',
        sendingTmp: 'Sending...',
        notActivated: 'Not activated',
        plsEnterSenderID: 'Pls enter SenderID (option)',
        regularSMS: 'Regular SMS',
        variableSMS: 'Variable SMS',
        importVariables: 'Import Variables',
        used: 'Used ',
        SMS: 'SMS',
        charactersNumber: 'No more than 350 characters and maximum 500 characters',
        referenceContent: '(For reference only, actual coding and charging are based on the full content)',
        validNumbersOnly: 'For valid numbers only, invalid numbers, duplicate numbers do not support sending.',
        purged: 'Purged',
        SMSLengthChargingStardard: 'SMS length and charging stardard',
        countriesReached: 'Countries reached',
        support: 'Support',
        unsubscribeReturnT: 'To unsubscribe, return T',
        plsAddValidNumber: 'Pls add valid number',
        senderIDMinimum3Digits: 'SenderID minimum 3 digits',
        senderIdFormatError: 'SenderId format error, by 3-11 bits',
        senderIdCannotContainChinese: 'SenderId cannot contain Chinese characters',
        plsFillSMSContent: 'Pls fill in SMS content',
        plsSelectScheduledTimeSend: 'Pls select scheduled time to send',
        scheduledTimeTips: 'The scheduled time cannot be less than the current time and requires more than five minutes',
        // : '本次共计发送有效号码XX 个，消耗XX条短信—This batch consists a total of XX valid numbers, consuming XX SMS',
        confirmSending: 'Confirm sending',
        cancelSending: 'Cancel sending',
        prompt: 'Prompt',
        insufficientBalance: 'Insufficient balance',
        sendSucceedContinue: 'Send succeed, continue to submit?',
        continueSend: 'Continue to send',
        checkRecord: 'Check record',
        submitFailed: 'Submit failed',
        submitAbnormal: 'Submit abnormal',
        submitSucceed: 'Submit succeed!',
        abnormalAction: 'Abnormal action!',
        submittingRequestExit: 'Submitting request, confirm to exit?',
        scheduledSubmission: 'Scheduled submission',
        submissionRecord: 'Submission record',
        plsEnterApplicationID : 'Pls enter application ID ',
        submittedTimeScheduledTime: 'Submitted time/ Scheduled time',
        cancelSending: 'Cancel Sending',
        cancelled: 'Cancelled',
        sent: 'Sent',
        confirmExportBatchDetails: 'Confirm to export this batch of details?',
        confirmCancelSending: 'Confirm to cancel sending?',
        cancelSucceed: 'Cancel succeed',
        submittedScheduledSendTime: 'Submitted time/Scheduled time/Send time',
        submitSendingTime: 'Submit time/Sending time',
        submittedStatus: 'Submitted status',
        receivingStatus: 'Receiving status',
        termsConditionsStatement: 'Terms and Conditions statement',
        accountBalanceAvailable: 'Indicate legacy account balance available',
        maximumOverdraftCreditLimits: 'indicate Maximum overdraft credit limits and remaining credit limits when the balance is 0',
        confirmExit: 'Confirm to exit?',
        disclaimer: 'disclaimer',
        informationSecurityCertificates: 'Information security certificates',
        returnUpperLevel: 'Return to upper level',
        passed: 'Passed',
        invoiceBalance: 'Invoice balance',
        accountAmountLessThan: 'Account amount less than ',
        warningAlert: 'Warning Alert',
        plsEnterAmount: 'Pls enter amount',
        creditLimitBelow: 'Credit Limit Below ',
        warningTips: 'Warning Tips',
        emailCannotBlank: `Notification email can't be blank`,
        balanceSetupSucceed: 'Balance setup succeed',
        submittedTotal: 'Submitted in total',
        succeedTotal: 'Succeed in total',
        failedTotal: 'failed in total',
        unknownTotal: 'Unknown in total',
        invalidTotal: 'Invalid in total',
        DRTotal: 'DR in total',
        welcometo : 'Welcome to ',
        emailCustomerIDPhoneNumber: 'Email/Customer ID/Phone number',
        plsEnterEmailCustomerIDPhoneNumber: 'Pls enter email/Customer ID/Phone number',
        plsEnterEmailCustomerID: 'Pls enter email/Customer ID',
        pwdOTP: 'Pwd/OTP',
        plsEnterPwdOTP: 'Pls enter Pwd/OTP',
        plsEnterPwd: 'Pls enter Pwd',
        forgottenPwd: 'Forgotten Pwd?',
        plsEnterEmailCustomerID: 'Pls enter email/customer ID',
        charactersLength: '3 to 50 characters in length',
        plsEnterLoginPwd: 'Pls enter login Pwd',
        loginSuccess: 'Login Success!',
        loginFailed: 'Login Failed',
        fileUploadSucceed: 'File upload succeed',
        fileUploadFailed: 'File upload failed',
        downloadTemplate: 'Download template?',
        plsUploadFile: 'Pls upload file!',
        uploadFileSize: 'Upload file size cannot exceed 500MB!',
        uploadFileFormatIncorrect: 'Upload file format is incorrect!',
        amendSucceed: 'Amend succeed',
        amendFailed: 'Amend Failed',
        phoneTips: 'Please enter phone number, multiple numbers by "," separated, phone number needs to be prefix with area code,like',
        detectedSuggest: `We have detected that your current input quantity is relatively large. We suggest that you use the "batch import" function`,
        phoneNumberAbnormal: 'Phone number abnormal',
        loginTimeoutRetry: 'Login time out, pls retry.',
        resending: 'Resending...',
        pwdTips: 'A password that supports letters, numbers, special characters and is 8 ~ 16 bits long',
        plsEnterCorrectEmail: 'Pls enter correct email',
        OTPSentPlsCheck: 'OTP sent, pls check',
        OTPFailed: 'OTP failed',
        plsEnterCorrectEmailAddress: 'Pls enter correct Email address',
        pwdChangeSucceedLoginAgain: 'Pwd change succeed! Pls login again',
        submitting: 'Submitting',
        lengthBetweenCharacter: 'Length between 4 to 6 Character',
        billOverview: 'Bill overview',
        product: 'Product',
        MOCharge: 'MO Charge',
        initialCost: 'Initial Cost',
        rentalCost: 'Rental Cost',
        chargedSMS: 'Charged SMS',
        amountUsed: 'Amount used',
        invoiceDetail: 'Invoice Detail',
        totalVolume: 'Total Volume',
        unitPrice: 'Unit price',
        amount: 'Amount',
        total: 'Total',
        invoiceNo: 'Invoice No.',
        invoiceName: 'Invoice Name',
        customerPaymentType: 'Customer payment type',
        billingTimeZone: 'Billing Time zone',
        cycle: 'Cycle',
        billingStatus: 'Billing status',
        billingAmount: 'Billing amount',
        discAmount: 'Disc amount',
        finalAmount: 'Final amount',
        amountIncludedTAX: 'Amount included TAX',
        finalInvoiceAmount: 'Final Invoice amount',
        previewInvoice: 'Preview Invoice',
        product: 'Product',
        MOCharge: 'MO Charge',
        initialCost: 'Initial Cost',
        rentalCost: 'Rental Cost',
        chargedSMS: 'Charged SMS',
        amountUsed: 'Amount used',
        invoiceDetail: 'Invoice Detail',
        totalVolume: 'Total Volume',
        unitPrice: 'Unit price',
        amount: 'Amount',
        total: 'Total',
        export: 'Export?',
        adjustInvoice: 'Adjust Invoice',
        settlementCurrency: 'Settlement Currency',
        pleaseSelectCurrency: 'Please select currency',
        invoiceAmount: 'Invoice Amount',
        discount: 'Discount?',
        cashDisc: 'Cash Disc',
        discOnDisc: 'Disc on Disc',
        taxRate: 'Tax Rate',
        amountIncludedTax: 'Amount included Tax',
        offsetInvoice: 'Offset Invoice',
        invoiceDetailTmp: 'Invoice detail',
        writeOffAmount: 'Write-off amount',
        linkedTopUpBill : 'Linked Top up bill',
        linkedTopUpBillTmp: 'Linked Top up bill',
        addTopUpBill: 'add Top up Bill',
        topUpBill: 'Top up Bill',
        plsSelectTopUpBill : 'Pls select Top up Bill ',
        topUpBillAmount: 'Top up bill amount',
        amountToBeWrittenOff: 'Amount to be written-off',
        amountToBeWriteOff: 'Amount to be write-off',
        plsEnterOffsetAmount: 'Pls enter offset amount',
        clickToFill: 'Click to Fill',
        writtenOff: 'Written off',
        toBeOffset: 'To be offset',
        newCustomerBill: 'New customer bill',
        checkOffsetRecord: 'Check offset record',
        billOverview: 'Bill overview',
        confirmExportBill: 'Confirm to export bill?',
        amountTips: 'The total write-off amount cannot be greater than the amount that can be offset in the  bill',
        offsetBillSucceed: 'offset bill succeed!',
        discountMethod: 'Discount Method',
        cashOff: 'Cash off',
        plsEnterOffAmount: 'Pls enter off amount',
        offDiscount: 'Off discount',
        plsEnterOffDiscount: 'Pls enter off discount',
        plsEnterTaxRate: 'Pls enter tax rate',
        plsEnterFinalBillAmount: 'Pls enter final bill amount',
        adjustBillSucceed: 'Adjust bill succeed!',
        discount: 'Discount',


        SMSLengthStandards: 'SMS Length and Charging Standards',
        SMSp1: `- Depending on the text message encoding, the number of SMS messages per message will be proportional to the length of the text:`,
        SMSp2: `- For GMS7 messages:`,
        SMSp3: `- If the total length of the message is less than or equal to 160 characters, the first and only part of the message can hold 160 characters.`,
        SMSp4: `- If the total length exceeds 160 characters, each message section can contain 153 characters (fewer characters can be placed into one section because additional data space is needed to connect SMS on the target phone)`,
        SMSp5: `- For Unicode messages:`,
        SMSp6: `- If the total length of the message is less than or equal to 70 characters, the first and only part of the message can hold 70 characters.`,
        SMSp7: `- If the total length exceeds 70 characters, each message section can contain 67 characters (fewer characters can be placed into one section because additional data space is needed to connect SMS on the target phone)`,
        SMSp8: `- For ASCII messages:`,
        SMSp9: `- If the total message length is less than or equal to 140 characters, the first and only message part can hold 140 characters.`,
        SMSp10: `- If the total length exceeds 140 characters, each message section can contain 134 characters (fewer characters can be placed into one section because additional data space is needed to connect SMS on the target phone)`,
        SMSp11: `- If you send an x part with a length equal to the message, you will pay for the x text message.`,
        palphaDisclaimer: 'Planet Alpha Disclaimer',
        paPrivacyPolicy: 'Planet Alpha Privacy Policy',
        ppH1: '1. Definition',
        ppH1_1: 'The terms contained in the Agreement and these Terms and Conditions in bold have been defined with the meaning set forth in this article:',
        ppH1_2: 'Agreement: Each Agreement and/or Order entered into by Planet Alpha with the Customer and these Terms and Conditions shall apply to these Agreements and/or Orders.',
        ppH1_3: 'Applicable data protection laws: All applicable laws and regulations applicable to Planet Alpha and/or the processing of personal data by customers.',
        ppH1_4: 'Applicable law: national, local or other laws, rules, regulations, enforceable regulatory guidance, orders, judgments, ordinances or decisions in force at the time in any jurisdiction in which the Customer accesses and uses the Services, including but not limited to data protection and privacy regulations, guidelines, conditions and policy rules, and/or regulations, guidelines and conditions applicable to operators of services and/or end-user services.',
        ppH1_5: 'Customer: Planet Alpha is the party to the agreement.',
        ppH1_6: 'Customer Material: All information, data, content and other materials in any form or medium provided to Planet Alpha by or on behalf of the Customer.',
        ppH1_7: 'Planet Alpha: Planet Alpha, the company that has entered into an agreement with the Customer as defined in the applicable order.',
        ppH1_8: 'Planet Alpha Intellectual Property: Services, Software, Planet Alpha Platform and all improvements, modifications, enhancements or derivatives of the foregoing, and all intellectual property rights of any of the foregoing.',
        ppH1_9: `Planet Alpha Platform: Planet Alpha's computing environment, designed to provide services and create connections between carrier networks and customer systems.`,
        ppH1_10: 'DPA: The Data Processing Appendix, available on [the Website], forms part of these Terms and Conditions and of the agreement between the Customer and Planet Alpha regarding the use of the Services by the Customer.',
        ppH1_11: 'Intellectual Property: Any and all intellectual property of any nature worldwide, valid for the entire term of protection of such intellectual property, including all (a) patents, registered trademarks, service marks, copyright, design and any and all applications for registration of any of the above; (b) unregistered trademarks, service marks, design, design rights and copyright; and (c) expertise, trade secrets, and any rights or interests of the foregoing in any manner whatsoever.',
        ppH1_12: 'Effective Date: Refers to the date on which a particular agreement takes effect.',
        ppH1_13: `End User: A natural person or entity authorized by the Customer to use the Customer's End User Services.`,
        ppH1_14: 'End User Services: Services that customers provide to their end users.',
        ppH1_15: 'Carrier: An electronic communications service provider or provider of over-the-top (OTT) communication services to its customers (including Planet Alpha), subscribers to end users and other carriers providing (wireless) messaging, voice and data communications and other related services. Order: Refers to an agreement.',
        ppH1_16: 'Personal data: any information relating to identified or identifiable natural persons ("data subjects"); An identifiable natural person is a natural person that can be identified directly or indirectly, in particular by reference to such factors as name, identification number, location data, online identification information or identifiers specific to the physical, physical, genetic, psychological, economic, cultural or social identity of that natural person.',
        ppH1_17: `Service: Planet Alpha's services to customers under the agreement, These may include electronic communications, professional services, access to applications as software as a service provider, and services providing two platform services. Includes access to and use of the Planet Alpha platform, any application programming interface (Planet Alpha API) and/or communication services related to the Planet Alpha platform or Planet Alpha API.`,
        ppH1_18: 'Traffic: Electronic communication and/or data traffic transmitted from and to mobile phones and/or fixed lines, mobile devices or online applications, including but not limited to any data exchanged. Traffic includes, but is not limited to, SMS (short message service), MMS (multimedia SMS), Push (push message), OTT, RCS (converged communication), voice and/or data.',
        ppH1_19: 'Working days: Monday to Friday from 8:30 a.m. to 5:00 p.m., excluding generally recognized public holidays, according to the time zone where the Planet Alpha entity is serving.',
        ppH2: '2. Scope',
        ppH2_1: '2.1 These Terms and Conditions apply to all agreements between Planet Alpha and the Customer except as expressly agreed by the parties in writing.',
        ppH2_2: '2.2 The applicability of any general (purchase) terms and conditions used by the Customer, or of the Agreement and any other terms and conditions other than those contained in these Terms and Conditions, is expressly excluded.',
        ppH2_3: '2.3 Both parties hereby agree that, to the fullest extent permitted by law, any electronic signature shall have the same legal force and enforceability as a handwritten signature. For the purposes of these Terms and Conditions, any reference to "written" or "written" means any form of written communication, including but not limited to electronic signatures, and any such written communication may be transmitted electronically.',
        ppH2_4: '2.4 Applicable law applies to end user services provided by the Customer to the end user in the country in which the end user service is delivered. The Customer shall be familiar with such applicable law applicable to the End User Service (including seeking independent legal advice where required). Planet Alpha makes no warranty and is not responsible for this.',
        ppH2_5: '2.5. Services are published under the Planet Alpha Service Level Agreement ("SLA") and are expressly incorporated into these Terms and Conditions by reference.',
        ppH2_6: '2.6. The DPA, Terms of Use, Country-Specific Terms, and Governing and Dispute Terms published on [the Website] form part of these Terms and Conditions and of the agreement between the Customer and Planet Alpha regarding the use of the Services by the Customer.',
        ppH2_7: '2.7 If there is any conflict or inconsistency between the various documents constituting the agreement, the following order of priority (the documents referred to first take precedence over those referred to later) shall apply: (i) Agreement, (ii) these Terms and Conditions, and (iii) any other documents contained in or referenced in any of the aforementioned documents.',
        ppH3: '3. Prices, payments, taxes and disputes',
        ppH3_1: '3.1 The Customer shall pay all fees set forth in the Agreement. Fees shall be paid in the currency specified in the agreement. Fees do not include VAT (VAT) and/or any other taxes, charges or fees imposed by any government agency.',
        ppH3_2: '3.2 The Customer shall pay for all traffic received and received from the Planet Alpha Platform. The applicable traffic fee is available on the Planet Alpha website. Planet Alpha reserves the right to change these content without notice. The Planet Alpha billing period is based on the CE (S) T (Central European Time (CET)) time zone. Cost calculations should refer to Planet Alpha records, not to any customer records. Planet Alpha reserves the right to change its traffic fees at all times in accordance with changes in applicable law and/or changes in rates imposed by the Operator.',
        ppH3_3: '3.3 Traffic charges do not include any applicable government, regulatory or carrier fees or surcharges that may apply to a particular destination. In addition to the traffic fee, the Customer shall pay all such surcharges.',
        ppH3_4: `3.4 Planet Alpha has the right to index costs annually in line with last year's changes in the Consumer Price Index (CPI). Planet Alpha will notify indexed information by simple notification and/or by stating it on the bill issued to the customer.`,
        ppH3_5: '3.5 The standard credit line for customer accounts is US $2,000 (US $2.000). When the customer reaches a 75% credit limit, Planet Alpha sends an electronic notification (e-mail) to the customer.',
        ppH3_6: `3.6 Upon receipt of a Credit Line Notification, the Customer will be able to pay the outstanding bill for which Planet Alpha has not received payment. Due and timely payment will prevent the customer's account from being (temporarily) frozen when the credit line is reached. The customer may request an increase in the applicable credit limit. The Customer hereby agrees that Planet Alpha may, at its sole discretion, notify the Customer at any time by e-mail of any modification (increase or decrease) or cancellation of the Credit Limit with immediate effect. Requests for change of credit must be approved in writing by Planet Alpha. Planet Alpha reserves the right to suspend the Service without prior notice and/or liability until the credit limit is increased or the Customer pays the bill. Planet Alpha is under no obligation to provide any services until payment and/or increase is received.`,
        ppH3_7: '3.7 The Customer shall pay all bill amounts within fourteen (14) days of the date of the Bill and shall not suspend payment, set off or deduct, except where the Agreement expressly contains different payment terms.',
        ppH3_8: '3.8 If the Customer fails to pay the maturity amount to Planet Alpha within the agreed payment period, interest shall be paid on the outstanding bill amount, which shall be the maximum amount permitted by applicable law, and no breach of contract shall be notified by Planet Alpha separately. The Client will compensate Planet Alpha for all reasonable expenses and expenses (including but not limited to reasonable legal fees) incurred in the collection of any overdue payments or interest.',
        ppH3_9: '3.9 For international, non-single-dollar payment area (SEPA) transactions, banks processing transactions may charge banking fees. In such cases, the Customer shall bear the fees charged by the bank, its bank, any intermediary bank and Planet Alpha, as indicated on the bill at the time of payment under the Agreement. The net amount received by Planet Alpha should match the amount on the bill. For this reason, the customer remains responsible for any difference.',
        ppH3_10: 'Planet Alpha reserves the right to set off the amount owed to it by Planet Alpha.',
        ppH3_11: '3.11 The Customer may object in good faith to the Bill within the payment period applicable to the Bill, provided that the Customer makes timely payment of any undisputed part of the Bill.',
        ppH3_12: `3.12 If the Customer has a prepaid account, the fees charged to the Customer in connection with the use of the particular Service shall be deducted from the prepaid account balance. You may use the Services for which such fees are charged only if a corresponding minimum amount has been prepaid to the Customer's prepaid account.`,
        ppH3_13: '3.13 Prepaid deposits and balances may be used by the Customer on the next day of receipt of payment from Planet Alpha. Prepaid deposits are non-refundable and will expire twelve (12) months from the date the customer purchases the prepaid balance.',
        ppH3_14: `3.14 If a credit card payment is returned, the customer is in default. In this case, Planet Alpha reserves the right to offset the amount of the refund against the balance. Without the balance, Planet Alpha reserves the right to terminate the Customer's prepaid account.`,
        ppH3_15: '3.15 The Customer will indemnify Planet Alpha for the prepaid balance misused by third parties.',
        ppH3_16: '3.16 Planet Alpha reserves the right to refuse registration of a prepaid account and to terminate an existing account for any reason. If the account balance is not replenished within three (3) months after registration or after all credits have been used, the prepaid account will be cancelled.',
        ppH4: '4. Obligations',
        ppH4_1: `4.1 The Customer shall, upon request in writing, reasonably cooperate with law enforcement, regulatory or operator requests for information or Planet Alpha requests for information in order to comply with its legal obligations.`,
        ppH4_2: `4.2 The Customer shall ensure that access to the Planet Alpha Platform and the Customer Account for the Services is limited to the Customer Authorized Person and that login credentials are securely stored. Customer is responsible for access to customer accounts on the Planet Alpha platform and services. including but not limited to all activities that occur on their accounts, even if not authorized by the customer, Also includes all traffic sent/delivered through customer accounts, legality of traffic and legal relationships with end users, This includes whether the end user provides valid consent to receive traffic. Customer guarantees that the End User Services comply with applicable (data protection) laws and do not infringe any third party rights. Customer will maintain up-to-date end-user consent records. Customer shall not send any spam, unsolicited traffic or other unethical, illegal, punishable or otherwise fraudulent or illegal traffic or content, and shall not violate Planet Alpha's anti-spam policy.`,
        ppH4_3: `4.3 Customer shall use the Service only for its intended and normal purposes and/or for the purposes agreed upon and stated in the Agreement. Without limitation, the Customer may not: (i) reverse engineer any part of the Service or otherwise attempt unauthorized access; (ii) resell the Service or otherwise use the Service or allow any person to use the Service for any purpose other than the Customer's benefit under the Agreement; (iii) interfere with or (attempt to) destroy the integrity or provision of the Service, or any data or content contained or transmitted therein; (iv) collect or collect data about the Services through the Services, except for the functions of the Services provided by Planet Alpha expressly for such purposes; or (v) use the Services or any Planet Alpha confidential information to benchmark competitive or competitive analysis of products or services, or to develop, commercialize, license or sell any products, services or technologies that may directly or indirectly compete with the Services.`,
        ppH5: '5. Guarantees and compensation',
        ppH5_1: `5.1 The Customer disclaims and warrants (i) that it has obtained all necessary rights, authorizations and licenses to access and use the Customer's Materials; (ii) Planet Alpha's use of Customer Material under the Agreement will not violate any applicable (data protection) laws or result in a breach of any agreement or obligation between the Customer and any third party; and (iii) the promotion of end-user services will not infringe or violate any intellectual property rights of any third party.`,
        ppH5_2: `5.2 Unless it is unenforceable under applicable law, Notwithstanding the provisions of article 8 and without any restrictions, All losses, damages, liabilities and expenses suffered by Planet Alpha as a result of any third-party claims, litigation or legal proceedings against Planet Alpha arising out of (including reasonable legal fees) (hereinafter referred to as "loss") The Customer will indemnify Planet Alpha and defend it against the above losses: (i) Customer's use of the Services, End User Services, or Customer Materials, including but not limited to: any claims that Customer's use of the Services, End User Services or Customer Materials infringes, misappropriates or otherwise violates the intellectual property, privacy or other rights of any third party; (ii) any claim that the use, provision, transmission, display or storage of Customer Material is in violation of applicable (data protection) law, and/or (iii) the Customer's use of the Services in a manner inconsistent with the Agreement. Planet Alpha will immediately notify the Customer of any claim for compensation and will reasonably cooperate with the Customer in its defence and/or settlement. The Client and Planet Alpha shall make every reasonable effort to coordinate their course of action in relation to the defence of such claims.`,
        ppH6: '6. Confidentiality and disclosure',
        ppH6_1: `6.1 In these Terms and Conditions, "confidential information" means one party ("disclosing party") to the other party in relation to the agreement ("Recipient") provided (whether oral or written) any information of a confidential nature that is or should reasonably be considered confidential, given the nature and/or disclosure of the information. For clarity, the service will be treated as confidential information for Planet Alpha. However, any information or material is not confidential: (i) information or material that is generally known or available to the public on or after the date of disclosure other than for breach of the confidentiality of the Agreement; (ii) information or material that the recipient has duly known before obtaining it from the disclosure party; (iii) information or material duly obtained by the recipient from a third party that has the right to disclose such information or material without violating any obligation of confidentiality to the disclosing party; or (iv) does not use or contact any confidential information of the disclosing Party, information or material developed independently by or for the receiving Party. WITHOUT LIMITING THE FOREGOING, NOTHING IN THESE TERMS AND CONDITIONS SHALL LIMIT OR RESTRICT THE USE OF PLANET ALPHA OR ANY DISCLOSURE OBTAINED BY PLANET ALPHA OR ITS PERSONNEL IN FULFILLING PLANET ALPHA'S OBLIGATIONS UNDER THESE TERMS AND CONDITIONS Ability to know-how, experience, concepts and/or ideas in general.`,
        ppH6_2: '6.2 The Recipient shall strictly keep confidential the confidential information of the Disclosing Party and shall not use the confidential information of the Disclosing Party except in such circumstances as may be necessary for the performance and exercise of its obligations or rights under these Terms and Conditions. The Recipient shall not disclose any confidential information of the Disclosing Party except as follows: (i) disclose to the employee, representative or contractor of the recipient, provided that the employee, representative or contractor does need to be aware of such confidential information, and is bound by a written agreement that contains at least the same protection as the limitations contained in these Terms and Conditions, (ii) To make a disclosure pursuant to an order or request of a court, administrative body, securities market or government agency, provided that the recipient gives the disclosing party a prior written notice of a reasonable period of time in order for the disclosing party to seek an order of protection or otherwise challenge the disclosure.',
        ppH6_3: '6.3 The obligations of the parties in respect of confidential information shall take effect from the effective date and shall expire three (3) years after the termination of the Agreement; However, as long as any confidential information constituting trade secrets (as determined by applicable law) remains protected by trade secrets under applicable law, the obligation of confidentiality shall remain in effect after the termination or expiration of the Agreement.',
        ppH6_4: `6.4 The terms and conditions of the agreement shall constitute confidential information of the parties, but may be disclosed on a confidential basis to a party's consultants, lawyers, actual or real potential acquirers, investors or other sources of funding (and their respective consultants and lawyers) for the purpose of due diligence.`,
        ppH6_5: `6.5 The Customer hereby grants Planet Alpha the right and license to use the Customer's name, trademark, and corporate logo in marketing, sales, financial, and public relations materials and other communications to identify the Customer as a Client of Planet Alpha. With the prior written approval of Planet Alpha, Planet Alpha hereby grants the customer a clear right. The name and logo of Planet Alpha is used only to indicate that Planet Alpha is the customer's service provider. All goodwill and reputation enhancements resulting from the use of Planet Alpha's name and logo are exclusive to Planet Alpha; Planet Alpha uses all the goodwill and reputation enhancements brought about by the customer's name and logo, with Planet Alpha as the exclusive beneficiary. Except as expressly stated in these Terms and Conditions, no party shall use the name, logo, logo, URL or specification of the other party without the prior written consent of the other party.`,
        ppH7: '7. Intellectual Property Rights',
        ppH7_1: '7.1 Subject to the limited rights expressly granted under these Terms and Conditions, both parties expressly reserve all rights, title and interest in the intellectual property rights of the parties. Except as expressly provided in these Terms and Conditions, no rights have been granted to the other party under these Terms and Conditions.',
        ppH8: '8. Responsibility',
        ppH8_1: `8.1 The Customer acknowledges that access to the Internet, telecommunications networks and other communication media will be subject to uncertainties related to (including but not limited to) service availability, transmission reliability, authorization, authenticity and data security. Planet Alpha does not guarantee that the service is or will be error-free, bug-free, or uninterrupted. Planet Alpha is not responsible for any stoppage, slowdown, service interruption, performance problems or any other issues resulting from circumstances beyond its control. but not limited to interference with telephone and/or telecommunications transmission and/or carrier networks, defects or limitations in Customer's hardware, systems, software or processes, defects or limitations in Internet or wireless connections. In the event of errors, vulnerabilities, or interruptions, Planet Alpha shall make reasonable efforts to restore the Services in accordance with the industry's recognized and appropriate practices.`,
        ppH8_2: '8.2 Services and Planet Alpha platforms are provided "as is." Planet Alpha makes no warranties or representations to the Customer or to any other party regarding the Planet Alpha Platform, including the Services or any other services provided under these Terms and Conditions. To the fullest extent permitted by applicable law, Planet Alpha hereby disclaims all warranties and representations, express or implied, including, without limitation, any implied warranties of merchantability and fitness for a particular purpose. Without limiting the foregoing, Planet Alpha hereby disclaims that there is no guarantee of error, vulnerability or interruption in the use of the Planet Alpha platform.',
        ppH8_3: '8.3 Without prejudice to the provisions of Article 5, no party shall be liable to the other party under any of the following agreements: (i) Loss of profits, income or sales; (ii) transaction losses; (iii) loss of opportunity; (iv) the cost or other economic loss of alternative services, (v) the inability to use any services or any computer equipment; (vi) loss of time by management or other staff; (vii) professional expenses or expenses; (viii) business interruption; (ix) data corruption or loss; (x) loss of goodwill or reputation, or (xi) Any indirect, special, incidental, exemplary, punitive, or consequential damages of any kind or kind arising under the Agreement, even if notified of the possibility of such damages, whether under tort (including negligence), breach of contract, strict liability or any claim arising from other reasons.',
        ppH8_4: `8.4 Without prejudice to the provisions of Article 5, the total liability of Planet Alpha arising out of or in connection with the Agreement shall not exceed the total amount paid by the Customer within 12 months prior to the first event in which the liability was incurred, in the amount of US $50,000 (€50,000) or the local currency equivalent is capped. In no event shall Planet Alpha's total compensation for any and all claims under the Agreement exceed the equivalent amount of $50,000 ($50,000) or local currency.`,
        ppH8_5: '8.5 To the fullest extent permitted by applicable law, the limitations and exclusions of liability set forth in this Article 8 shall not apply to death or bodily injury caused by wilful misconduct or gross negligence of a party.',
        ppH9: '9. Force Majeure',
        ppH9_1: '9.1 If a party is unable by force majeure to perform any obligation under the Agreement other than the obligation to pay the amount due under this Agreement, that party shall be exempt from such obligation. In the case of an agreement, the term "force majeure" includes, but is not limited to: Government intervention, strikes, diseases, pandemics, infectious diseases, acts or threats of terrorism, interruption of operations, disruption of energy resources, interruption of third-party telecommunications facilities, and all other circumstances that cannot reasonably be avoided or prevented by one party.',
        ppH9_2: '9.2 If a party is unable to fulfil its obligations due to force majeure, it shall inform the other party within ten (10) working days from the date of force majeure.',
        ppH10: '10. Duration, suspension and termination of the Agreement',
        ppH10_1: '10.1 The initial period of the Agreement begins on the effective date and expires at the end of the initial period specified in the Agreement (hereinafter referred to as the "initial period"). If no initial period is specified in the agreement, the initial period will expire after one (1) year from the effective date.',
        ppH10_2: '10.2 After the initial period expires, the agreement will be automatically renewed for one (1) year (Each renewal is referred to as the "renewal period" and the initial period is referred to as the "duration") Unless either party sends a written notice of termination to the other party three (3) months prior to the end of the initial or renewal period at that time.',
        ppH10_3: '10.3 If the Customer violates any of the terms of the Agreement, Planet Alpha shall have the right to suspend the Service without notice and shall not limit any other relief available to Planet Alpha, including termination of the Agreement and/or claim actual performance and/or damages. During any such suspension, the Customer remains responsible for any due and payable costs under the Agreement.',
        ppH10_4: `10.4 As soon as the reasons for the suspension of service under this Article cease to exist, Planet Alpha shall immediately resume service delivery, except in cases where Planet Alpha considers that the nature and/or frequency of Customer's non-compliance is sufficient to result in the permanent cancellation of service.`,
        ppH10_5: '10.5 If any party breaches the Agreement and fails to correct the breach within ten (10) working days of receipt of a written notice of breach, the other party shall have the right to terminate the Agreement upon notice in writing to that Party.',
        ppH10_6: '10.6 This Agreement may be further terminated by either Party by notice in writing to the other Party if:',
        ppH10_6_a: 'a. The other party becomes the subject of a voluntary or involuntary bankruptcy application or of proceedings relating to insolvency, takeover, liquidation or settlement in the interests of creditors;',
        ppH10_6_b: 'b. the force majeure described in Article 9 lasts for more than twenty (20) working days;',
        ppH10_6_c: `c. The regulatory authority requires that the termination be enforced or that the termination be required as a result of changes in the Operator's Terms and Conditions.`,
        ppH10_7: '10.7 In case of termination referred to in articles 10.5 and 10.6, The Customer has obtained any services provided during the performance of the Agreement. Any amount that Planet Alpha billed for the Services prior to termination shall expire in full and shall be paid immediately upon termination.',
        ppH10_8: '10.8 Provisions which by their nature are expected to remain in force after the termination of the agreement shall continue to apply after the expiration of the agreement. These provisions include, but are not limited to, Article 5 of the Agreement (Guarantees and compensation), article 6 (Confidentiality and disclosure) Article 7 (Intellectual property), Article 8 (Liability), Article 9 (force majeure) Article 10 (Duration, Suspension and Termination of Agreement) and the provisions of article 12 (Applicable law and disputes).',
        ppH11: '11. Transfer of rights and obligations; Subcontracting',
        ppH11_1: '11.1 No party may transfer the Agreement in whole or in part without the written consent of the other party (no unreasonable refusal to do so); However, either party may, without prior notice or permission, transfer agreements to any of its affiliates or to third parties that inherit all or a substantial portion of its business and assets in connection with the subject matter of the agreement, whether through mergers, acquisitions, the sale of its majority stake, the sale of the majority of its assets or similar transactions. Any transfer prohibited is null and void. Subject to the foregoing, the agreement shall be binding on, and shall be the beneficiary of, the successor and the authorized assignee.',
        ppH12: '12. Applicable Laws and Disputes',
        ppH12_1: `12.1 The Agreement (and any matters arising out of or in connection with the Agreement) shall be governed by and construed in accordance with the laws of the applicable state or country as defined in the Governing Law and Dispute Terms, without regard to conflicts of laws and principles that may lead to the application of other jurisdictions' laws. The parties hereby expressly agree that the United Nations Convention on Contracts for the International Sale of Goods shall not apply.`,
        ppH12_2: '12.2 Any dispute arising out of or in connection with the Agreement, including questions relating to the existence, validity or termination of the Agreement, shall be subject to the exclusive jurisdiction of the competent court as defined in the Governing Law and the Dispute Clause.',
        ppH13: '13. Other provisions',
        ppH13_1: '13.1 The Agreement constitutes the complete agreement between the parties with respect to the subject matter of the Agreement and supersedes any and all prior or contemporaneous representations, agreements and agreements between the Parties with respect to the subject matter of the Agreement.',
        ppH13_2: '13.2. Failure to immediately implement a provision of the Agreement shall not be construed as a waiver of that provision. Nothing in the Agreement shall be considered or construed as creating a joint venture or partnership between the parties.',
        ppH13_3: `13.3. Neither party shall be authorized by agreement or otherwise to act as an agent or legal representative of the other. Neither party is granted any right or authority to assume or create any express or implied obligation or liability on behalf of the other party or to bind the other party in any way. Nothing in the Agreement shall be deemed to establish any third-party benefit for any third party, but Planet Alpha's parent company and affiliates shall be third-party beneficiaries of specific terms of the Agreement and such terms and conditions (including, but not limited to, the provisions on intellectual property, compensation, exemption, arbitration, liability and waiver) will apply to such parent companies and affiliates.`,
        ppH13_4: '13.4. Upon request by Planet Alpha, the Customer shall provide information that Planet Alpha deems necessary to meet its reporting obligations, including reporting obligations under the Corporate Sustainability Reporting Directive. Planet Alpha does not require a declaration of importance. The Customer shall provide the requested information within twenty (20) business days or elaborate on the reasons for the inability to obtain such information and the expected time at which such information may be obtained. To the extent reasonably possible, the Customer shall include similar provisions in its agreements with third parties provided that it is reasonably anticipated that such third parties may be requested to provide information in accordance with these Terms.',
        ppH13_5: '13.5. The Parties recognize that they have the opportunity to review the Agreement by an independent counsel of their choice. If any of the provisions of the Agreement are held to be invalid or unenforceable in whole or in part, the provision deemed invalid or unenforceable shall be deemed amended, And the courts or other government departments have the power to amend such provisions to the extent necessary to make them valid, enforceable and consistent with the intentions of the parties referred to in the Agreement.',
        ppH13_6: `13.6 All notices requested or permitted under the Agreement shall be in writing and shall refer to the Agreement. All notifications sent to Planet Alpha should be sent to the address of the relevant Planet Alpha entity, and all notifications sent to the customer should be sent to the address specified in the relevant agreement; or to another address specified by the relevant party to the other party in accordance with section 13.8. Such notice shall be deemed to have been served at the following times: (i) if delivered by a person, at the time of submission; (ii) delivered through a nationally recognized courier company, which shall be deemed to have been served upon delivery for one (1) working day and upon receipt of confirmation in writing; or (iii) sent by registered mail or warranty mail with prepaid postage (required receipt) shall be deemed to have been served three (3) business days after issue.`,
        ppH13_7: '13.7 Planet Alpha reserves the right to modify the Agreement and these Terms and Conditions at any time. If there are any significant modifications, Planet Alpha will inform the customer. If the Customer does not object in writing within one month of the date of the Notice of Modification, the Customer is deemed to have accepted the modification to the Terms and Conditions. If the customer objects, the previous Terms and Conditions apply. However, Planet Alpha reserves the right to terminate its agreement with the Customer by a written notice of one (1) month in advance.',
        ppH14: '14. Processing of personal data',
        ppH14_1: '14.1 Planet Alpha may process personal data as an independent controller or as a processor on behalf of the Customer. Planet Alpha processes personal data on behalf of customers, DPA shall apply. This Section 14 shall apply to the processing of personal data by Planet Alpha as controller.',
        ppH14_2: `14.2 Processing of Personal Data in Planet Alpha Involving a Customer's Relationship with Planet Alpha (Includes the name and contact details of the customer's employee or person authorized to access the customer's account) Or when customer due diligence requires personal data, Planet Alpha determines the purpose and manner of processing, and Planet Alpha is eligible as an independent controller under applicable data protection laws. Such processing includes personal data processed for the following purposes: Account and relationship management, marketing, financial and billing data related to customer use services, customer support, credit checks, fraud and criminal activity prevention, identification, and Know Your Customer (KYC) processes`,
        ppH14_3: '14.3 When Planet Alpha processes personal data as a public electronic communications service provider, Planet Alpha shall act as an independent controller for the processing of personal data necessary for the operation of electronic communications services in accordance with applicable telecommunications laws and applicable data protection laws. Such processing includes personal data processed for traffic, billing, fraud, anti-spam and compliance purposes.',
        ppH14_4: '14.4 In processing personal data pursuant to articles 14.2 and 14.3 above, Planet Alpha shall comply with its obligations under the applicable (data protection) law.',
        ppH14_5: `14.5 Notwithstanding any provisions of this Article 14 and/or DPA, Planet Alpha has the right to collect, extract, compile, synthesize and analyze non-personally identifiable data or information generated by the Customer's use or operation of the Service, including, but not limited to, information relating to quantity, frequency, rate of leap, or any other information relating to the use of the Services ("Service Data") arising from the use of the Services by customers, their end users or recipients. If Planet Alpha collects or generates any service data, the data will be owned separately by Planet Alpha, Planet Alpha may be used for any legitimate internal or external business purpose. There is no accounting liability to the customer, provided that the data is used only in the form of aggregation and de-identification.`,



        palphaDisclaimerLP: 'Les-Ponts Disclaimer',
        paPrivacyPolicyLP: 'Les-Ponts Privacy Policy',
        ppH1LP: '1. Definition',
        ppH1_1LP: 'The terms contained in the Agreement and these Terms and Conditions in bold have been defined with the meaning set forth in this article:',
        ppH1_2LP: 'Agreement: Each Agreement and/or Order entered into by Les-Ponts with the Customer and these Terms and Conditions shall apply to these Agreements and/or Orders.',
        ppH1_3LP: 'Applicable data protection laws: All applicable laws and regulations applicable to Les-Ponts and/or the processing of personal data by customers.',
        ppH1_4LP: 'Applicable law: national, local or other laws, rules, regulations, enforceable regulatory guidance, orders, judgments, ordinances or decisions in force at the time in any jurisdiction in which the Customer accesses and uses the Services, including but not limited to data protection and privacy regulations, guidelines, conditions and policy rules, and/or regulations, guidelines and conditions applicable to operators of services and/or end-user services.',
        ppH1_5LP: 'Customer: Les-Ponts is the party to the agreement.',
        ppH1_6LP: 'Customer Material: All information, data, content and other materials in any form or medium provided to Les-Ponts by or on behalf of the Customer.',
        ppH1_7LP: 'Les-Ponts: Les-Ponts, the company that has entered into an agreement with the Customer as defined in the applicable order.',
        ppH1_8LP: 'Les-Ponts Intellectual Property: Services, Software, Les-Ponts Platform and all improvements, modifications, enhancements or derivatives of the foregoing, and all intellectual property rights of any of the foregoing.',
        ppH1_9LP: `Les-Ponts Platform: Les-Ponts's computing environment, designed to provide services and create connections between carrier networks and customer systems.`,
        ppH1_10LP: 'DPA: The Data Processing Appendix, available on [the Website], forms part of these Terms and Conditions and of the agreement between the Customer and Les-Ponts regarding the use of the Services by the Customer.',
        ppH1_11LP: 'Intellectual Property: Any and all intellectual property of any nature worldwide, valid for the entire term of protection of such intellectual property, including all (a) patents, registered trademarks, service marks, copyright, design and any and all applications for registration of any of the above; (b) unregistered trademarks, service marks, design, design rights and copyright; and (c) expertise, trade secrets, and any rights or interests of the foregoing in any manner whatsoever.',
        ppH1_12LP: 'Effective Date: Refers to the date on which a particular agreement takes effect.',
        ppH1_13LP: `End User: A natural person or entity authorized by the Customer to use the Customer's End User Services.`,
        ppH1_14LP: 'End User Services: Services that customers provide to their end users.',
        ppH1_15LP: 'Carrier: An electronic communications service provider or provider of over-the-top (OTT) communication services to its customers (including Les-Ponts), subscribers to end users and other carriers providing (wireless) messaging, voice and data communications and other related services. Order: Refers to an agreement.',
        ppH1_16LP: 'Personal data: any information relating to identified or identifiable natural persons ("data subjects"); An identifiable natural person is a natural person that can be identified directly or indirectly, in particular by reference to such factors as name, identification number, location data, online identification information or identifiers specific to the physical, physical, genetic, psychological, economic, cultural or social identity of that natural person.',
        ppH1_17LP: `Service: Les-Ponts's services to customers under the agreement, These may include electronic communications, professional services, access to applications as software as a service provider, and services providing two platform services. Includes access to and use of the Les-Ponts platform, any application programming interface (Les-Ponts API) and/or communication services related to the Les-Ponts platform or Les-Ponts API.`,
        ppH1_18LP: 'Traffic: Electronic communication and/or data traffic transmitted from and to mobile phones and/or fixed lines, mobile devices or online applications, including but not limited to any data exchanged. Traffic includes, but is not limited to, SMS (short message service), MMS (multimedia SMS), Push (push message), OTT, RCS (converged communication), voice and/or data.',
        ppH1_19LP: 'Working days: Monday to Friday from 8:30 a.m. to 5:00 p.m., excluding generally recognized public holidays, according to the time zone where the Les-Ponts entity is serving.',
        ppH2LP: '2. Scope',
        ppH2_1LP: '2.1 These Terms and Conditions apply to all agreements between Les-Ponts and the Customer except as expressly agreed by the parties in writing.',
        ppH2_2LP: '2.2 The applicability of any general (purchase) terms and conditions used by the Customer, or of the Agreement and any other terms and conditions other than those contained in these Terms and Conditions, is expressly excluded.',
        ppH2_3LP: '2.3 Both parties hereby agree that, to the fullest extent permitted by law, any electronic signature shall have the same legal force and enforceability as a handwritten signature. For the purposes of these Terms and Conditions, any reference to "written" or "written" means any form of written communication, including but not limited to electronic signatures, and any such written communication may be transmitted electronically.',
        ppH2_4LP: '2.4 Applicable law applies to end user services provided by the Customer to the end user in the country in which the end user service is delivered. The Customer shall be familiar with such applicable law applicable to the End User Service (including seeking independent legal advice where required). Les-Ponts makes no warranty and is not responsible for this.',
        ppH2_5LP: '2.5. Services are published under the Les-Ponts Service Level Agreement ("SLA") and are expressly incorporated into these Terms and Conditions by reference.',
        ppH2_6LP: '2.6. The DPA, Terms of Use, Country-Specific Terms, and Governing and Dispute Terms published on [the Website] form part of these Terms and Conditions and of the agreement between the Customer and Les-Ponts regarding the use of the Services by the Customer.',
        ppH2_7LP: '2.7 If there is any conflict or inconsistency between the various documents constituting the agreement, the following order of priority (the documents referred to first take precedence over those referred to later) shall apply: (i) Agreement, (ii) these Terms and Conditions, and (iii) any other documents contained in or referenced in any of the aforementioned documents.',
        ppH3LP: '3. Prices, payments, taxes and disputes',
        ppH3_1LP: '3.1 The Customer shall pay all fees set forth in the Agreement. Fees shall be paid in the currency specified in the agreement. Fees do not include VAT (VAT) and/or any other taxes, charges or fees imposed by any government agency.',
        ppH3_2LP: '3.2 The Customer shall pay for all traffic received and received from the Les-Ponts Platform. The applicable traffic fee is available on the Les-Ponts website. Les-Ponts reserves the right to change these content without notice. The Les-Ponts billing period is based on the CE (S) T (Central European Time (CET)) time zone. Cost calculations should refer to Les-Ponts records, not to any customer records. Les-Ponts reserves the right to change its traffic fees at all times in accordance with changes in applicable law and/or changes in rates imposed by the Operator.',
        ppH3_3LP: '3.3 Traffic charges do not include any applicable government, regulatory or carrier fees or surcharges that may apply to a particular destination. In addition to the traffic fee, the Customer shall pay all such surcharges.',
        ppH3_4LP: `3.4 Les-Ponts has the right to index costs annually in line with last year's changes in the Consumer Price Index (CPI). Les-Ponts will notify indexed information by simple notification and/or by stating it on the bill issued to the customer.`,
        ppH3_5LP: '3.5 The standard credit line for customer accounts is US $2,000 (US $2.000). When the customer reaches a 75% credit limit, Les-Ponts sends an electronic notification (e-mail) to the customer.',
        ppH3_6LP: `3.6 Upon receipt of a Credit Line Notification, the Customer will be able to pay the outstanding bill for which Les-Ponts has not received payment. Due and timely payment will prevent the customer's account from being (temporarily) frozen when the credit line is reached. The customer may request an increase in the applicable credit limit. The Customer hereby agrees that Les-Ponts may, at its sole discretion, notify the Customer at any time by e-mail of any modification (increase or decrease) or cancellation of the Credit Limit with immediate effect. Requests for change of credit must be approved in writing by Les-Ponts. Les-Ponts reserves the right to suspend the Service without prior notice and/or liability until the credit limit is increased or the Customer pays the bill. Les-Ponts is under no obligation to provide any services until payment and/or increase is received.`,
        ppH3_7LP: '3.7 The Customer shall pay all bill amounts within fourteen (14) days of the date of the Bill and shall not suspend payment, set off or deduct, except where the Agreement expressly contains different payment terms.',
        ppH3_8LP: '3.8 If the Customer fails to pay the maturity amount to Les-Ponts within the agreed payment period, interest shall be paid on the outstanding bill amount, which shall be the maximum amount permitted by applicable law, and no breach of contract shall be notified by Les-Ponts separately. The Client will compensate Les-Ponts for all reasonable expenses and expenses (including but not limited to reasonable legal fees) incurred in the collection of any overdue payments or interest.',
        ppH3_9LP: '3.9 For international, non-single-dollar payment area (SEPA) transactions, banks processing transactions may charge banking fees. In such cases, the Customer shall bear the fees charged by the bank, its bank, any intermediary bank and Les-Ponts, as indicated on the bill at the time of payment under the Agreement. The net amount received by Les-Ponts should match the amount on the bill. For this reason, the customer remains responsible for any difference.',
        ppH3_10LP: 'Les-Ponts reserves the right to set off the amount owed to it by Les-Ponts.',
        ppH3_11LP: '3.11 The Customer may object in good faith to the Bill within the payment period applicable to the Bill, provided that the Customer makes timely payment of any undisputed part of the Bill.',
        ppH3_12LP: `3.12 If the Customer has a prepaid account, the fees charged to the Customer in connection with the use of the particular Service shall be deducted from the prepaid account balance. You may use the Services for which such fees are charged only if a corresponding minimum amount has been prepaid to the Customer's prepaid account.`,
        ppH3_13LP: '3.13 Prepaid deposits and balances may be used by the Customer on the next day of receipt of payment from Les-Ponts. Prepaid deposits are non-refundable and will expire twelve (12) months from the date the customer purchases the prepaid balance.',
        ppH3_14LP: `3.14 If a credit card payment is returned, the customer is in default. In this case, Les-Ponts reserves the right to offset the amount of the refund against the balance. Without the balance, Les-Ponts reserves the right to terminate the Customer's prepaid account.`,
        ppH3_15LP: '3.15 The Customer will indemnify Les-Ponts for the prepaid balance misused by third parties.',
        ppH3_16LP: '3.16 Les-Ponts reserves the right to refuse registration of a prepaid account and to terminate an existing account for any reason. If the account balance is not replenished within three (3) months after registration or after all credits have been used, the prepaid account will be cancelled.',
        ppH4LP: '4. Obligations',
        ppH4_1LP: `4.1 The Customer shall, upon request in writing, reasonably cooperate with law enforcement, regulatory or operator requests for information or Les-Ponts requests for information in order to comply with its legal obligations.`,
        ppH4_2LP: `4.2 The Customer shall ensure that access to the Les-Ponts Platform and the Customer Account for the Services is limited to the Customer Authorized Person and that login credentials are securely stored. Customer is responsible for access to customer accounts on the Les-Ponts platform and services. including but not limited to all activities that occur on their accounts, even if not authorized by the customer, Also includes all traffic sent/delivered through customer accounts, legality of traffic and legal relationships with end users, This includes whether the end user provides valid consent to receive traffic. Customer guarantees that the End User Services comply with applicable (data protection) laws and do not infringe any third party rights. Customer will maintain up-to-date end-user consent records. Customer shall not send any spam, unsolicited traffic or other unethical, illegal, punishable or otherwise fraudulent or illegal traffic or content, and shall not violate Les-Ponts's anti-spam policy.`,
        ppH4_3LP: `4.3 Customer shall use the Service only for its intended and normal purposes and/or for the purposes agreed upon and stated in the Agreement. Without limitation, the Customer may not: (i) reverse engineer any part of the Service or otherwise attempt unauthorized access; (ii) resell the Service or otherwise use the Service or allow any person to use the Service for any purpose other than the Customer's benefit under the Agreement; (iii) interfere with or (attempt to) destroy the integrity or provision of the Service, or any data or content contained or transmitted therein; (iv) collect or collect data about the Services through the Services, except for the functions of the Services provided by Les-Ponts expressly for such purposes; or (v) use the Services or any Les-Ponts confidential information to benchmark competitive or competitive analysis of products or services, or to develop, commercialize, license or sell any products, services or technologies that may directly or indirectly compete with the Services.`,
        ppH5LP: '5. Guarantees and compensation',
        ppH5_1LP: `5.1 The Customer disclaims and warrants (i) that it has obtained all necessary rights, authorizations and licenses to access and use the Customer's Materials; (ii) Les-Ponts's use of Customer Material under the Agreement will not violate any applicable (data protection) laws or result in a breach of any agreement or obligation between the Customer and any third party; and (iii) the promotion of end-user services will not infringe or violate any intellectual property rights of any third party.`,
        ppH5_2LP: `5.2 Unless it is unenforceable under applicable law, Notwithstanding the provisions of article 8 and without any restrictions, All losses, damages, liabilities and expenses suffered by Les-Ponts as a result of any third-party claims, litigation or legal proceedings against Les-Ponts arising out of (including reasonable legal fees) (hereinafter referred to as "loss") The Customer will indemnify Les-Ponts and defend it against the above losses: (i) Customer's use of the Services, End User Services, or Customer Materials, including but not limited to: any claims that Customer's use of the Services, End User Services or Customer Materials infringes, misappropriates or otherwise violates the intellectual property, privacy or other rights of any third party; (ii) any claim that the use, provision, transmission, display or storage of Customer Material is in violation of applicable (data protection) law, and/or (iii) the Customer's use of the Services in a manner inconsistent with the Agreement. Les-Ponts will immediately notify the Customer of any claim for compensation and will reasonably cooperate with the Customer in its defence and/or settlement. The Client and Les-Ponts shall make every reasonable effort to coordinate their course of action in relation to the defence of such claims.`,
        ppH6LP: '6. Confidentiality and disclosure',
        ppH6_1LP: `6.1 In these Terms and Conditions, "confidential information" means one party ("disclosing party") to the other party in relation to the agreement ("Recipient") provided (whether oral or written) any information of a confidential nature that is or should reasonably be considered confidential, given the nature and/or disclosure of the information. For clarity, the service will be treated as confidential information for Les-Ponts. However, any information or material is not confidential: (i) information or material that is generally known or available to the public on or after the date of disclosure other than for breach of the confidentiality of the Agreement; (ii) information or material that the recipient has duly known before obtaining it from the disclosure party; (iii) information or material duly obtained by the recipient from a third party that has the right to disclose such information or material without violating any obligation of confidentiality to the disclosing party; or (iv) does not use or contact any confidential information of the disclosing Party, information or material developed independently by or for the receiving Party. WITHOUT LIMITING THE FOREGOING, NOTHING IN THESE TERMS AND CONDITIONS SHALL LIMIT OR RESTRICT THE USE OF Les-Ponts OR ANY DISCLOSURE OBTAINED BY Les-Ponts OR ITS PERSONNEL IN FULFILLING Les-Ponts'S OBLIGATIONS UNDER THESE TERMS AND CONDITIONS Ability to know-how, experience, concepts and/or ideas in general.`,
        ppH6_2LP: '6.2 The Recipient shall strictly keep confidential the confidential information of the Disclosing Party and shall not use the confidential information of the Disclosing Party except in such circumstances as may be necessary for the performance and exercise of its obligations or rights under these Terms and Conditions. The Recipient shall not disclose any confidential information of the Disclosing Party except as follows: (i) disclose to the employee, representative or contractor of the recipient, provided that the employee, representative or contractor does need to be aware of such confidential information, and is bound by a written agreement that contains at least the same protection as the limitations contained in these Terms and Conditions, (ii) To make a disclosure pursuant to an order or request of a court, administrative body, securities market or government agency, provided that the recipient gives the disclosing party a prior written notice of a reasonable period of time in order for the disclosing party to seek an order of protection or otherwise challenge the disclosure.',
        ppH6_3LP: '6.3 The obligations of the parties in respect of confidential information shall take effect from the effective date and shall expire three (3) years after the termination of the Agreement; However, as long as any confidential information constituting trade secrets (as determined by applicable law) remains protected by trade secrets under applicable law, the obligation of confidentiality shall remain in effect after the termination or expiration of the Agreement.',
        ppH6_4LP: `6.4 The terms and conditions of the agreement shall constitute confidential information of the parties, but may be disclosed on a confidential basis to a party's consultants, lawyers, actual or real potential acquirers, investors or other sources of funding (and their respective consultants and lawyers) for the purpose of due diligence.`,
        ppH6_5LP: `6.5 The Customer hereby grants Les-Ponts the right and license to use the Customer's name, trademark, and corporate logo in marketing, sales, financial, and public relations materials and other communications to identify the Customer as a Client of Les-Ponts. With the prior written approval of Les-Ponts, Les-Ponts hereby grants the customer a clear right. The name and logo of Les-Ponts is used only to indicate that Les-Ponts is the customer's service provider. All goodwill and reputation enhancements resulting from the use of Les-Ponts's name and logo are exclusive to Les-Ponts; Les-Ponts uses all the goodwill and reputation enhancements brought about by the customer's name and logo, with Les-Ponts as the exclusive beneficiary. Except as expressly stated in these Terms and Conditions, no party shall use the name, logo, logo, URL or specification of the other party without the prior written consent of the other party.`,
        ppH7LP: '7. Intellectual Property Rights',
        ppH7_1LP: '7.1 Subject to the limited rights expressly granted under these Terms and Conditions, both parties expressly reserve all rights, title and interest in the intellectual property rights of the parties. Except as expressly provided in these Terms and Conditions, no rights have been granted to the other party under these Terms and Conditions.',
        ppH8LP: '8. Responsibility',
        ppH8_1LP: `8.1 The Customer acknowledges that access to the Internet, telecommunications networks and other communication media will be subject to uncertainties related to (including but not limited to) service availability, transmission reliability, authorization, authenticity and data security. Les-Ponts does not guarantee that the service is or will be error-free, bug-free, or uninterrupted. Les-Ponts is not responsible for any stoppage, slowdown, service interruption, performance problems or any other issues resulting from circumstances beyond its control. but not limited to interference with telephone and/or telecommunications transmission and/or carrier networks, defects or limitations in Customer's hardware, systems, software or processes, defects or limitations in Internet or wireless connections. In the event of errors, vulnerabilities, or interruptions, Les-Ponts shall make reasonable efforts to restore the Services in accordance with the industry's recognized and appropriate practices.`,
        ppH8_2LP: '8.2 Services and Les-Ponts platforms are provided "as is." Les-Ponts makes no warranties or representations to the Customer or to any other party regarding the Les-Ponts Platform, including the Services or any other services provided under these Terms and Conditions. To the fullest extent permitted by applicable law, Les-Ponts hereby disclaims all warranties and representations, express or implied, including, without limitation, any implied warranties of merchantability and fitness for a particular purpose. Without limiting the foregoing, Les-Ponts hereby disclaims that there is no guarantee of error, vulnerability or interruption in the use of the Les-Ponts platform.',
        ppH8_3LP: '8.3 Without prejudice to the provisions of Article 5, no party shall be liable to the other party under any of the following agreements: (i) Loss of profits, income or sales; (ii) transaction losses; (iii) loss of opportunity; (iv) the cost or other economic loss of alternative services, (v) the inability to use any services or any computer equipment; (vi) loss of time by management or other staff; (vii) professional expenses or expenses; (viii) business interruption; (ix) data corruption or loss; (x) loss of goodwill or reputation, or (xi) Any indirect, special, incidental, exemplary, punitive, or consequential damages of any kind or kind arising under the Agreement, even if notified of the possibility of such damages, whether under tort (including negligence), breach of contract, strict liability or any claim arising from other reasons.',
        ppH8_4LP: `8.4 Without prejudice to the provisions of Article 5, the total liability of Les-Ponts arising out of or in connection with the Agreement shall not exceed the total amount paid by the Customer within 12 months prior to the first event in which the liability was incurred, in the amount of US $50,000 (€50,000) or the local currency equivalent is capped. In no event shall Les-Ponts's total compensation for any and all claims under the Agreement exceed the equivalent amount of $50,000 ($50,000) or local currency.`,
        ppH8_5LP: '8.5 To the fullest extent permitted by applicable law, the limitations and exclusions of liability set forth in this Article 8 shall not apply to death or bodily injury caused by wilful misconduct or gross negligence of a party.',
        ppH9LP: '9. Force Majeure',
        ppH9_1LP: '9.1 If a party is unable by force majeure to perform any obligation under the Agreement other than the obligation to pay the amount due under this Agreement, that party shall be exempt from such obligation. In the case of an agreement, the term "force majeure" includes, but is not limited to: Government intervention, strikes, diseases, pandemics, infectious diseases, acts or threats of terrorism, interruption of operations, disruption of energy resources, interruption of third-party telecommunications facilities, and all other circumstances that cannot reasonably be avoided or prevented by one party.',
        ppH9_2LP: '9.2 If a party is unable to fulfil its obligations due to force majeure, it shall inform the other party within ten (10) working days from the date of force majeure.',
        ppH10LP: '10. Duration, suspension and termination of the Agreement',
        ppH10_1LP: '10.1 The initial period of the Agreement begins on the effective date and expires at the end of the initial period specified in the Agreement (hereinafter referred to as the "initial period"). If no initial period is specified in the agreement, the initial period will expire after one (1) year from the effective date.',
        ppH10_2LP: '10.2 After the initial period expires, the agreement will be automatically renewed for one (1) year (Each renewal is referred to as the "renewal period" and the initial period is referred to as the "duration") Unless either party sends a written notice of termination to the other party three (3) months prior to the end of the initial or renewal period at that time.',
        ppH10_3LP: '10.3 If the Customer violates any of the terms of the Agreement, Les-Ponts shall have the right to suspend the Service without notice and shall not limit any other relief available to Les-Ponts, including termination of the Agreement and/or claim actual performance and/or damages. During any such suspension, the Customer remains responsible for any due and payable costs under the Agreement.',
        ppH10_4LP: `10.4 As soon as the reasons for the suspension of service under this Article cease to exist, Les-Ponts shall immediately resume service delivery, except in cases where Les-Ponts considers that the nature and/or frequency of Customer's non-compliance is sufficient to result in the permanent cancellation of service.`,
        ppH10_5LP: '10.5 If any party breaches the Agreement and fails to correct the breach within ten (10) working days of receipt of a written notice of breach, the other party shall have the right to terminate the Agreement upon notice in writing to that Party.',
        ppH10_6LP: '10.6 This Agreement may be further terminated by either Party by notice in writing to the other Party if:',
        ppH10_6_aLP: 'a. The other party becomes the subject of a voluntary or involuntary bankruptcy application or of proceedings relating to insolvency, takeover, liquidation or settlement in the interests of creditors;',
        ppH10_6_bLP: 'b. the force majeure described in Article 9 lasts for more than twenty (20) working days;',
        ppH10_6_cLP: `c. The regulatory authority requires that the termination be enforced or that the termination be required as a result of changes in the Operator's Terms and Conditions.`,
        ppH10_7LP: '10.7 In case of termination referred to in articles 10.5 and 10.6, The Customer has obtained any services provided during the performance of the Agreement. Any amount that Les-Ponts billed for the Services prior to termination shall expire in full and shall be paid immediately upon termination.',
        ppH10_8LP: '10.8 Provisions which by their nature are expected to remain in force after the termination of the agreement shall continue to apply after the expiration of the agreement. These provisions include, but are not limited to, Article 5 of the Agreement (Guarantees and compensation), article 6 (Confidentiality and disclosure) Article 7 (Intellectual property), Article 8 (Liability), Article 9 (force majeure) Article 10 (Duration, Suspension and Termination of Agreement) and the provisions of article 12 (Applicable law and disputes).',
        ppH11LP: '11. Transfer of rights and obligations; Subcontracting',
        ppH11_1LP: '11.1 No party may transfer the Agreement in whole or in part without the written consent of the other party (no unreasonable refusal to do so); However, either party may, without prior notice or permission, transfer agreements to any of its affiliates or to third parties that inherit all or a substantial portion of its business and assets in connection with the subject matter of the agreement, whether through mergers, acquisitions, the sale of its majority stake, the sale of the majority of its assets or similar transactions. Any transfer prohibited is null and void. Subject to the foregoing, the agreement shall be binding on, and shall be the beneficiary of, the successor and the authorized assignee.',
        ppH12LP: '12. Applicable Laws and Disputes',
        ppH12_1LP: `12.1 The Agreement (and any matters arising out of or in connection with the Agreement) shall be governed by and construed in accordance with the laws of the applicable state or country as defined in the Governing Law and Dispute Terms, without regard to conflicts of laws and principles that may lead to the application of other jurisdictions' laws. The parties hereby expressly agree that the United Nations Convention on Contracts for the International Sale of Goods shall not apply.`,
        ppH12_2LP: '12.2 Any dispute arising out of or in connection with the Agreement, including questions relating to the existence, validity or termination of the Agreement, shall be subject to the exclusive jurisdiction of the competent court as defined in the Governing Law and the Dispute Clause.',
        ppH13LP: '13. Other provisions',
        ppH13_1LP: '13.1 The Agreement constitutes the complete agreement between the parties with respect to the subject matter of the Agreement and supersedes any and all prior or contemporaneous representations, agreements and agreements between the Parties with respect to the subject matter of the Agreement.',
        ppH13_2LP: '13.2. Failure to immediately implement a provision of the Agreement shall not be construed as a waiver of that provision. Nothing in the Agreement shall be considered or construed as creating a joint venture or partnership between the parties.',
        ppH13_3LP: `13.3. Neither party shall be authorized by agreement or otherwise to act as an agent or legal representative of the other. Neither party is granted any right or authority to assume or create any express or implied obligation or liability on behalf of the other party or to bind the other party in any way. Nothing in the Agreement shall be deemed to establish any third-party benefit for any third party, but Les-Ponts's parent company and affiliates shall be third-party beneficiaries of specific terms of the Agreement and such terms and conditions (including, but not limited to, the provisions on intellectual property, compensation, exemption, arbitration, liability and waiver) will apply to such parent companies and affiliates.`,
        ppH13_4LP: '13.4. Upon request by Les-Ponts, the Customer shall provide information that Les-Ponts deems necessary to meet its reporting obligations, including reporting obligations under the Corporate Sustainability Reporting Directive. Les-Ponts does not require a declaration of importance. The Customer shall provide the requested information within twenty (20) business days or elaborate on the reasons for the inability to obtain such information and the expected time at which such information may be obtained. To the extent reasonably possible, the Customer shall include similar provisions in its agreements with third parties provided that it is reasonably anticipated that such third parties may be requested to provide information in accordance with these Terms.',
        ppH13_5LP: '13.5. The Parties recognize that they have the opportunity to review the Agreement by an independent counsel of their choice. If any of the provisions of the Agreement are held to be invalid or unenforceable in whole or in part, the provision deemed invalid or unenforceable shall be deemed amended, And the courts or other government departments have the power to amend such provisions to the extent necessary to make them valid, enforceable and consistent with the intentions of the parties referred to in the Agreement.',
        ppH13_6LP: `13.6 All notices requested or permitted under the Agreement shall be in writing and shall refer to the Agreement. All notifications sent to Les-Ponts should be sent to the address of the relevant Les-Ponts entity, and all notifications sent to the customer should be sent to the address specified in the relevant agreement; or to another address specified by the relevant party to the other party in accordance with section 13.8. Such notice shall be deemed to have been served at the following times: (i) if delivered by a person, at the time of submission; (ii) delivered through a nationally recognized courier company, which shall be deemed to have been served upon delivery for one (1) working day and upon receipt of confirmation in writing; or (iii) sent by registered mail or warranty mail with prepaid postage (required receipt) shall be deemed to have been served three (3) business days after issue.`,
        ppH13_7LP: '13.7 Les-Ponts reserves the right to modify the Agreement and these Terms and Conditions at any time. If there are any significant modifications, Les-Ponts will inform the customer. If the Customer does not object in writing within one month of the date of the Notice of Modification, the Customer is deemed to have accepted the modification to the Terms and Conditions. If the customer objects, the previous Terms and Conditions apply. However, Les-Ponts reserves the right to terminate its agreement with the Customer by a written notice of one (1) month in advance.',
        ppH14LP: '14. Processing of personal data',
        ppH14_1LP: '14.1 Les-Ponts may process personal data as an independent controller or as a processor on behalf of the Customer. Les-Ponts processes personal data on behalf of customers, DPA shall apply. This Section 14 shall apply to the processing of personal data by Les-Ponts as controller.',
        ppH14_2LP: `14.2 Processing of Personal Data in Les-Ponts Involving a Customer's Relationship with Les-Ponts (Includes the name and contact details of the customer's employee or person authorized to access the customer's account) Or when customer due diligence requires personal data, Les-Ponts determines the purpose and manner of processing, and Les-Ponts is eligible as an independent controller under applicable data protection laws. Such processing includes personal data processed for the following purposes: Account and relationship management, marketing, financial and billing data related to customer use services, customer support, credit checks, fraud and criminal activity prevention, identification, and Know Your Customer (KYC) processes`,
        ppH14_3LP: '14.3 When Les-Ponts processes personal data as a public electronic communications service provider, Les-Ponts shall act as an independent controller for the processing of personal data necessary for the operation of electronic communications services in accordance with applicable telecommunications laws and applicable data protection laws. Such processing includes personal data processed for traffic, billing, fraud, anti-spam and compliance purposes.',
        ppH14_4LP: '14.4 In processing personal data pursuant to articles 14.2 and 14.3 above, Les-Ponts shall comply with its obligations under the applicable (data protection) law.',
        ppH14_5LP: `14.5 Notwithstanding any provisions of this Article 14 and/or DPA, Les-Ponts has the right to collect, extract, compile, synthesize and analyze non-personally identifiable data or information generated by the Customer's use or operation of the Service, including, but not limited to, information relating to quantity, frequency, rate of leap, or any other information relating to the use of the Services ("Service Data") arising from the use of the Services by customers, their end users or recipients. If Les-Ponts collects or generates any service data, the data will be owned separately by Les-Ponts, Les-Ponts may be used for any legitimate internal or external business purpose. There is no accounting liability to the customer, provided that the data is used only in the form of aggregation and de-identification.`,
    }
}