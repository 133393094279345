import fetch from '@/utils/fetch';

// 查询配置信息
export function getPlatformConfig(params) {
    return fetch({
        url: '/client/platform/config/selectConfigInfo',
        method: 'get',
        params
    })
}

// 获取当前登入方式
export function getLoginMethod() {
    return fetch({
        url: '/client/platform/config/selectLoginMethod',
        method: 'get',
    })
}