import router from './router'
import store from './store'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/user/login', '/user/forgetPassword', '/user/registerAccount']

router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (to.path === '/user/login') {
      next()
    } else {
      store.dispatch('GetInfo').then((res) => {
        if(res.code==401){
          next({path:'/user/login'}) 
          ElMessage.error('登录失效，请重新登录')
          
        }else{
            next()
        }
      }).catch(err => {
        store.dispatch('LogOut').then(() => {
          ElMessage.error(err)
        })
      })
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({path:'/user/login'})  
    }
  }
})


