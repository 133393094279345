import axios from 'axios';
// import {getAppConfig} from '@/api/config';
import { getToken } from '@/utils/auth'
import store from '@/store'

//在main.js设置全局的请求次数，请求的间隙
axios.defaults.retry = 1;
axios.defaults.retryDelay = 1000;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    var config = err.config;
    // If config does not exist or the retry option is not set, reject
    if(!config || !config.retry) return Promise.reject(err);
    
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;
    
    // Check if we've maxed out the total number of retries
    if(config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(err);
    }
    
    // Increase the retry count
    config.__retryCount += 1;
    
    // Create new promise to handle exponential backoff
    var backoff = new Promise(function(resolve) {
        setTimeout(function() {
            resolve();
        }, config.retryDelay || 1);
    });
    
    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
        return axios(config);
    });
});
// 创建axios实例
const service = axios.create({
  baseURL: '',
  timeout: 1000 * 60 * 50                  // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
}
  return config
}, error => {
  Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    // response.data.code = 401
    const res = response.data;
    if (res.code === 200) {
      return response.data
    } else {
      // 401:未登录
      if (res.code === 401) {
        store.dispatch('LogOut').then(() => {
          location.href = '/user/login'
        });
      
      // setTimeout(() => {
      //   window.location.href = '/user/login'
      // }, 300)
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
      }else{
        return response.data
      }
    }
  },
  error => {
    // Message({
    //   message: '请求超时',
    //   type: 'error',
    //   duration: 20 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service;