const main = {
    state: {
        isLesPonts: false,
        hostName: 'alpha',
    },

    mutations: {
        SET_ISLESPONTS: (state, data) => {
            state.isLesPonts = data
        },
        SET_HOSTNAME: (state, data) => {
            state.hostName = data
        },
    },

    actions: {
        // 登录
        setIsLesPonts({ commit }, data) {
            commit('SET_ISLESPONTS', data)
        },
        setHostName({ commit }, data) {
            commit('SET_HOSTNAME', data)
        },

    }
}

export default main
