import fetch from '@/utils/fetch';

// 用户信息
export function getInfo() {
    return fetch({
        url: '/client/getUserInfo',
        method: 'get',
    })
}
//获取余额
export function getCustomerBalance() {
    return fetch({
        url: '/client/customerBalance',
        method: 'get',
    })
}
//余额设置
export function setCustomerBalance(data) {
    return fetch({
        url: '/client/setCustomerBalance',
        method: 'post',
        data
    })
}
//忘记密码
export function forgetPassword(data) {
    return fetch({
        url: '/client/changePassword',
        method: 'post',
        data
    })
}
//获取验证码
export function getValidateCode(params) {
    return fetch({
        url: '/client/getValidateCode',
        method: 'get',
        params
    })
}
//用户是否查看协议
export function getReadProtal(params) {
    return fetch({
        url: '/client/myReadProtal',
        method: 'get',
        params
    })
}
// 确认协议
export function sureProtal(data) {
    return fetch({
        url: '/client/readProtal',
        method: 'post',
        data
    })
}
//账户注册
export function registerAccount(data) {
    return fetch({
        url: '/client/customer/register',
        method: 'post',
        data
    })
}
//获取验证码
export function geRegisterValidateCode(email) {
    return fetch({
        url: '/client/get/verificationCode?email=' + email,
        method: 'get',
    })
}



