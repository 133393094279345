import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/utils/lang/index'
import echarts from './utils/echarts';
import '@/assets/icons/iconfont.css'
import './permission'
import zhLocale from "element-plus/es/locale/lang/zh-cn"

const app=createApp(App)

app.use(ElementPlus,{
    locale: zhLocale
})
app.config.globalProperties.$echarts = echarts

app.use(i18n)
app.use(store).use(router).mount('#app')