import fetch from '@/utils/fetch';

// 登录
export function loginIn(data) {
    return fetch({
        url: '/client/login',
        method: 'post',
        data
    })
}
export function loginOut() {
    return fetch({
        url: '/client/loginOut',
        method: 'get',
    })
}

// 忘记密码
// export function forgetPwd (obj) {
//     return fetch({
//         url: '/commons/forgetPwd',
//         method: 'post',
//         data: {
//             mobile: obj.mobile,
//             password: obj.password,
//             smsCode: obj.smsCode,
//             email:obj.email,
//             accountType:obj.accountType
//         }
//     })
// }