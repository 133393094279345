<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script setup>
import * as echarts from "echarts";
import { onMounted, provide, ref } from "vue";
import store from "./store";
import { getPlatformConfig, getLoginMethod } from '@/api/configInfo';

const webPlatformConfig = ref({
    webPlatformLogo: undefined,
    webPlatformName: undefined,
});
provide("webPlatformConfig", webPlatformConfig);
provide("echarts", echarts);

function init() {
    const local = location.host;
    // const local = 'www.les-ponts.com';
    // const local = 'web.planet-alpha.net';
    // const local = 'web.int.cryun.com';
    // store.dispatch('setIsLesPonts', local.includes('les-ponts'));

    // if(store.getters.isLesPonts) {

    // }

    if (local.includes('les-ponts')) {
        document.title = 'Les-Ponts';
        store.dispatch('setHostName', 'les-ponts');
    } else if (local.includes('cryun.com')) {
        document.title = 'CTW';
        store.dispatch('setHostName', 'ctw');
        const currentIcon = "/favicon_ctw.ico";
        const link = document.querySelector('link[rel*="icon"]');
        link.href = currentIcon;
    } else if (local.includes('zhangkenan.com')) {
        store.dispatch('setHostName', 'ctw');
    } else {
        document.title = 'Planet Alpha';
        store.dispatch('setHostName', 'planet-alpha');
    }

    getLoginMethod().then(res => {
        if (res.code == 200) {
            getPlatformConfig(res.data.loginMethod.includes('2') ? {loginPlatFormId: res.data.platForm || 1} : {}).then(response => {
            // getPlatformConfig(res.data.loginMethod == 1 ? {} : {loginPlatFormId: res.data.platForm || 1}).then(response => {
            if (response.code == 200) {
                // 若backendLogo存在，则设置
                const currentIcon = response.data.sysLogoConfigWebDTO.webFavoriteIcon;
                if (currentIcon) {
                    const link = document.querySelector('link[rel*="icon"]');
                    link.href = currentIcon;
                }

                const currentTitle = response.data.sysLogoConfigWebDTO.webPlatformName;
                if (currentTitle) {
                    webPlatformConfig.value.webPlatformName = currentTitle;
                    // this.configTitle = currentTitle;
                    document.title = currentTitle;
                }

                if (response.data.sysLogoConfigWebDTO.webPlatformLogo) {
                    webPlatformConfig.value.webPlatformLogo = response.data.sysLogoConfigWebDTO.webPlatformLogo;
                }
            }}).catch((err) => {
                console.log(err)
            });
        }
    })
}

onMounted(() => {
    init();
})
</script>
<style lang="scss">
html,
body,
#app {
    width: 100%;
    height: 100%;
}

@import "./assets/css/reset.scss";
</style>
