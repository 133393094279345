import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      { path: '',redirect: '/smsIndex' },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: { title: '控制台' }
      },
      {
        path: 'smsIndex',
        name: 'smsIndex',
        component: () => import('../views/sms/index.vue'),
        children: [
          { path: '', redirect: '/smsIndex/basic' },
          {
            path: 'basic',
            name: 'basic',
            component: () => import('../views/sms/basic.vue'),
            meta: { title: '数据概览' }
          },
          {
            path: 'appAccount',
            name: 'appAccount',
            component: () => import('../views/sms/appAccount.vue'),
            meta: { title: '应用账号' }
          },
          {
            path: 'send',
            name: 'send',
            component: () => import('../views/sms/send.vue'),
            meta: { title: '在线发送' }
          },
          {
            path: 'record',
            name: 'record',
            component: () => import('../views/sms/record.vue'),
            meta: { title: '发送记录' }
          },
        ]
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('../views/setting/index.vue'),
        children: [
          { path: '', redirect: '/setting/account' },
          {
            path: 'account',
            name: 'account',
            component: () => import('../views/setting/account.vue'),
            meta: { title: '账户信息' }
          },
          {
            path: 'balance',
            name: 'balance',
            component: () => import('../views/setting/balance.vue'),
            meta: { title: '余额' }
          },
          {
            path: 'topUpCenter',
            name: 'topUpCenter',
            component: () => import('../views/setting/topUpCenter.vue'),
            meta: { title: '充值中心' }
          },
          {
            path: 'bill',
            name: 'bill',
            component: () => import('../views/setting/bill.vue'),
            meta: { title: '账单' }
          },
          {
            path: 'disclaimers',
            name: 'disclaimers',
            component: () => import('../views/setting/disclaimers.vue'),
            meta: { title: '免责声明' }
          },
          {
            path: 'agreement',
            name: 'agreement',
            component: () => import('../views/setting/agreement.vue'),
            meta: { title: '用户协议' }
          },
          {
            path: 'infoSecurity',
            name: 'infoSecurity',
            component: () => import('../views/setting/infoSecurity.vue'),
            meta: { title: '信息安全责任证书' }
          },
        ]
      },
      {
        path: '/numberManage',
        name: 'numberManage',
        component: () => import('../views/numberManage/index.vue'),
        children: [
          { path: '', redirect: '/numberManage/purchasedNumber' },
          {
            path: 'purchasedNumber',
            name: 'purchasedNumber',
            component: () => import('../views/numberManage/purchasedNumber.vue'),
            meta: { title: '已购号码' }
          },
        ]
      },
      {
        path: '/upManage',
        name: 'upManage',
        component: () => import('../views/upManage/index.vue'),
        children: [
          { path: '/upManage', redirect: '/upManage/upBasic' },
          {
            path: 'upBasic',
            name: 'upBasic',
            component: () => import('../views/upManage/upBasic.vue'),
            meta: { title: '已购号码' }
          },
        ]
      },
      // upManage
    ]
  },
  {
    path: '/user', component: () => import('../views/user/index.vue'), children: [
      { path: '', redirect: '/user/login' },
      { path: 'login', name: 'login', component: () => import('../views/user/login/Login.vue') },
      { path: 'forgetPassword', name: 'forgetPassword', component: () => import('../views/user/forgetPassword/ForgetPassword.vue') },
      { path: 'registerAccount', name: 'registerAccount', component: () => import('../views/user/registerAccount/RegisterAccount.vue') },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/topUpDemo',
    name: 'demo',
    component: () => import('../views/topUpDemo.vue')
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
